import React, { Component } from 'react';
import $ from 'jquery';
import { toastSuccess, toastError } from '../../../helpers/toastHelpers';
import { updatePassword } from '../../../helpers/authHelpers';
import './passwordUpdate.scss';

const initialState = {
  newPassword: '',
  newPasswordConfirm: '',
  error: '',
};

class PasswordUpdateModal extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.handleInput = this.handleInput.bind(this);
    this.submitPasswordUpdate = this.submitPasswordUpdate.bind(this);
  }

  handleInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  submitPasswordUpdate(e) {
    e.preventDefault();
    updatePassword(this.state.newPassword)
      .then((response) => {
        if (response.status === 'success') {
          $('#updatePasswordModal').modal('hide');
          $('body').removeClass('modal-open');
          $('.modal-backdrop').remove();
          toastSuccess('Password updated successfully!');
          this.setState(initialState);
        } else {
          toastError('There was a problem updating your password!');
          this.setState({ error: response.message });
        }
      });
  }

  render() {
    return (
      <div id="psf-ffl-password-update-modal">
        <div
          className="modal fade"
          id="updatePasswordModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="updatePasswordModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <form className="px-4 py-3">
                <div className="modal-header">
                  <h5 className="modal-title" id="updatePasswordModalLabel">Password Update</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">

                  <div className="form-group">
                    <label htmlFor="newPassword">New Password</label>
                    <input
                      id="newPassword"
                      type="password"
                      className="form-control"
                      name="newPassword"
                      placeholder="Enter New Password"
                      onChange={this.handleInput}
                      value={this.state.newPassword}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="newPasswordConfirm">Confirm New Password</label>
                    <input
                      id="newPasswordConfirm"
                      type="password"
                      className="form-control"
                      name="newPasswordConfirm"
                      placeholder="Re-enter New Password"
                      onChange={this.handleInput}
                      value={this.state.newPasswordConfirm}
                    />
                  </div>
                  <span className="text-danger">{this.state.error}</span>
                </div>
                <div className="modal-footer">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <button type="submit" onClick={this.submitPasswordUpdate} className="btn btn-primary">Reset Password</button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordUpdateModal;
