import React, { useState, useRef, useCallback } from 'react';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFootballBall as footballIcon } from '@fortawesome/free-solid-svg-icons';

import { MAPBOX_API_KEY } from '../../helpers/constants.js';

import "mapbox-gl/dist/mapbox-gl.css";

function Map(props) {
  const [viewport, setViewport] = useState({
    latitude: 42.669269,
    longitude: -88.050844,
    zoom: 16,
    ...props.draftLocation,
  });
  const mapRef = useRef(null);
  const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), []);

  return (
    <div style={{ width: '100%', height: 'calc(100vh / 2)' }}>
      <ReactMapGL
        ref={mapRef}
        {...viewport}
        width="100%"
        height="100%"
        onViewportChange={handleViewportChange}
        mapboxApiAccessToken={MAPBOX_API_KEY}
        mapStyle="mapbox://styles/mapbox/streets-v9"
      >
        <Marker {...props.draftLocation} offsetLeft={5} offsetTop={-15}>
          <div className="text-green text-shadow-light">
            <FontAwesomeIcon icon={footballIcon} className="text-shadow-light mr-1" />
            <span className="text-bold text-italic text-background-yellow-light p-1">Heart Failure FFL Draft</span></div>
        </Marker>
        <div style={{ position: 'absolute', right: 0 }}>
          <NavigationControl />
        </div>
      </ReactMapGL>
    </div>
  );
}

export default Map;
