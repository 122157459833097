import React from 'react';
import Title from '../../components/Title';


const DraftOrder = () => (
  <div className="psf-container container">
    <Title text="2020 Draft Order" />
    <ol>
      <li>Matt H.</li>
      <li>Kurt F.</li>
      <li>Andy N.</li>
      <li>Pete K.</li>
      <li>Nick F.</li>
      <li>Kris K.</li>
      <li>Jeremy K.</li>
      <li>Tylor F.</li>
      <li>Mike B.</li>
      <li>Jon L.</li>
      <li>Mike D.</li>
      <li>Matt M.</li>
      <li>Dave R.</li>
      <li>Nick S.</li>
    </ol>
  </div>
);

export default DraftOrder;
