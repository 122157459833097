import React, { useState } from 'react';
import PropTypes from 'prop-types';

function TeamName(props) {
  const [teamName, setTeamName] = useState(props.initialName);

  const handleChange = (e) => {
    const { value } = e.target;
    props.onChange(value);
    setTeamName(value);
  };

  return (
    <div className={`row ${props.className}`}>
      <h3 className="d-block mb-4">Team Name</h3>

      <div className="col-12">
        <div className="form-group mb-4">
          <label className="label text-yellow-light" htmlFor="teamName">Team Name</label>
          <input
            type="text"
            className="form-control"
            id="teamName"
            placeholder="Team Name"
            value={teamName}
            onChange={handleChange}
            required
          />
          <small className="form-text text-mute text-italic mt-2">
            This will be the name used on draft day
            <span className="text-bold text-warn ml-2">
              Even if it is in ESPN, make sure it's here too!
            </span>
          </small>
        </div>
      </div>

    </div>
  );
}

TeamName.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  initialName: PropTypes.string,
};

TeamName.defaultProps = {
  onChange: () => null,
  className: '',
  initialName: '',
};

export default TeamName;