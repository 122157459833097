import React from 'react';
import { toast } from 'react-toastify';

export const toastSuccess = message => toast.success(
  <div className="container-fluid">
    <div className="row">
      <div className="col align-self-center">
        <span>{message}</span>
      </div>
    </div>
  </div>,
);

export const toastError = message => toast.error(
  <div className="container-fluid">
    <div className="row">
      <div className="col align-self-center">
        <span>{message}</span>
      </div>
    </div>
  </div>,
);
