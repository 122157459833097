import { firestore } from '../helpers/firebase';

const getDraft = async () => {
  const year = new Date().getFullYear().toString();
  const returnDraft = await firestore
    .collection('draft')
    .doc(year)
    .collection('draft')
    .get()
    .then((snapshot) => {
      let draft = {};
      snapshot.forEach((doc) => {
        draft = { ...draft, [doc.id]: doc.data() };
      });
      return { error: false, draft };
    })
    .catch(error => ({ error: true, message: error.message }));
  return returnDraft;
};

export default getDraft;

export const getDraftInPersonCount = async () => {
  const year = new Date().getFullYear().toString();
  const returnDraftCount = await firestore
    .collection('draft')
    .doc(year)
    .collection('draft')
    .where('draftInPerson', '==', true)
    .get()
    .then((snapshot) => ({ error: false, draftInPersonCount: snapshot.size }))
    .catch(error => ({ error: true, message: error.message }));
  return returnDraftCount;
};

export const getDraftEntries = async () => {
  const year = new Date().getFullYear().toString();
  const returnDraftCount = await firestore
    .collection('draft')
    .doc(year)
    .collection('draft')
    .get()
    .then((snapshot) => ({ error: false, draftEntries: snapshot.size }))
    .catch(error => ({ error: true, message: error.message }));
  return returnDraftCount;
};


export const draftFix = async () => {
  const year = '2019';

  // Get all of the draft info...
  const returnDraft = await firestore
    .collection('draft')
    .get()
    .then((snapshot) => {
      let draft = [];
      snapshot.forEach((doc) => {
        doc.id !== '2019' && doc.id !== '2020' && draft.push({ [doc.id]: doc.data() });
      });
      return { error: false, draft };
    })
    .catch(error => ({ error: true, message: error.message }));
  console.log(returnDraft);

  // Do the move to another location
  if (returnDraft.draft) {
    returnDraft.draft.forEach(async draftData => {
      for (const [userId, data] of Object.entries(draftData)) {

        const result = await firestore.collection('draft')
          .doc(year)
          .collection('draft')
          .doc(userId)
          .set(data)
          .then(() => ({ success: true, message: 'Successfully Updated!' }))
          .catch(error => ({ success: false, message: `Error Saving: ${error}` }));

        console.log(`${userId} ... ${result.success ? 'success' : 'failed'}`);
      }
    });
  }
};