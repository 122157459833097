import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';

const config = {
  apiKey: "AIzaSyAMcfkbxYS6rSgvfy46ZCdW9CGENqbOoCo",
  authDomain: "psf-ffl.firebaseapp.com",
  databaseURL: "https://psf-ffl.firebaseio.com",
  projectId: "psf-ffl",
  storageBucket: "psf-ffl.appspot.com",
  messagingSenderId: "591749579837",
  timestampsInSnapshots: true
};

const app = firebase.initializeApp(config);
app.firestore().settings({ experimentalAutoDetectLongPolling: true });
export const auth = app.auth();
export const firestore = app.firestore();
export const storage = app.storage();
export const timestamp = () => firebase.firestore.FieldValue.serverTimestamp();
