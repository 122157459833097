import { firestore } from '../helpers/firebase';

const setDraft = async (userId, data) => {
  const year = new Date().getFullYear().toString();
  let setDraftData = ({ success: false, message: 'Unknown Error' });
  try {
    setDraftData = await firestore.collection('draft')
      .doc(year)
      .collection('draft')
      .doc(userId)
      .set(data)
      .then(() => ({ success: true, message: 'Successfully Updated!' }))
      .catch(error => ({ success: false, message: `Error Saving: ${error}` }));
  } catch (error) {
    setDraftData = ({ success: false, message: `Error Saving: ${error.message}` });
  }

  return setDraftData;
};

export default setDraft;
