import React, { useEffect } from 'react';
import $ from 'jquery';

import Title from '../../components/Title';
import legendsClubData from './legendsClub.json';
import { titleHelper } from '../../helpers/titleHelper';
import './legendsClub.scss';

function LegendsClub() {
  useEffect(() => {
    $('#psf-ffl-carousel').carousel();
  });
  return (
    <div id="psf-ffl-legends-club" className="psf-container container">
      {titleHelper('Legends Club')}
      <Title text="Legends Club" />

      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col">

            <div id="psf-ffl-carousel-container">
              <div id="psf-ffl-carousel" className="carousel slide carousel-fade" data-ride="carousel" data-interval="3500">
                <ol className="carousel-indicators">
                  <li data-target="#psf-ffl-carousel" data-slide-to="0" className="active" />
                  {
                    Object.keys(legendsClubData).map((key, index) => <li data-target="#psf-ffl-carousel" key={`psf-carousel-key${key}`} data-slide-to={index + 1} />)
                  }
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img className="d-block w-100" src="/images/legends/legends-club-logo.jpg" alt="Legends Club Logo" />
                  </div>
                  {
                    Object.keys(legendsClubData).map((key, index) => {
                      const {
                        teamOwner, teamName, teamRecord, teamOwnerImageUrl,
                      } = legendsClubData[key];
                      return (
                        <div key={index} className="carousel-item container">
                          <img className="d-block w-100" src={`/images/legends/${teamOwnerImageUrl}`} alt={`${key} Champion - ${teamOwner}`} />

                          <div className="carousel-caption d-none d-md-block">
                            <h3>{key} Champion</h3>
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col">
                                  <h5>{teamOwner}</h5>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  {teamName}
                                </div>
                                <div className="col">
                                  {teamRecord}
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      );
                    })
                  }
                </div>
                <a className="carousel-control-prev" href="#psf-ffl-carousel" role="button" data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true" />
                  <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#psf-ffl-carousel" role="button" data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true" />
                  <span className="sr-only">Next</span>
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default LegendsClub;
