import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faRedo as iconRefresh,
} from '@fortawesome/free-solid-svg-icons';

import Title from '../../../components/Title';
import DraftDetailToggle from './components/DraftDetailToggle';
import { auth } from '../../../helpers/firebase';
import { toastError } from '../../../helpers/toastHelpers';
import getDraftForAll from '../../../data/getDraftForAll';
import getAllUsers from '../../../data/getAllUsers';
import getImageUrl from '../../../data/getImageUrl';
import { titleHelper } from '../../../helpers/titleHelper';
import './draftAdmin.scss';

class DraftAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: {},
      draft: {},
      error: null,
      loadingDraft: true,
      loadingUsers: true,
    };

    this.getAllUsersData = this.getAllUsersData.bind(this);
    this.getDraftData = this.getDraftData.bind(this);
  }

  componentDidMount() {
    titleHelper('Draft (Admin)');
    if (auth.currentUser) {
      this.getDraftData();
      this.getAllUsersData();
    }
  }

  getAllUsersData() {
    getAllUsers()
      .then((usersData) => {
        this.setState({ ...usersData, loadingUsers: false });
      });
  }

  async getDraftData() {
    const draft = await getDraftForAll();

    if (draft.error) {
      toastError(draft.message);
    } else {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key] of Object.entries(draft.draft)) {
        if (draft.draft[key].logoUpload) {
          // eslint-disable-next-line no-await-in-loop
          draft.draft[key].logoUrl = await getImageUrl(draft.draft[key].logoUploadFilename);
        }
        draft.draft[key].songList = draft.draft[key].songList ? JSON.parse(draft.draft[key].songList) : [];
      }
    }

    this.setState({ ...draft, loadingDraft: false });
  }

  render() {
    const users = Object.keys(this.state.users);
    return (
      <div id="psf-draft-admin-container" className="psf-container">
        <div className="container">
          <Title text="Draft Admin" />
        </div>

        <div className="container-fluid overflow-scroll shadow row-background p-3">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between mb-3">
                <DraftDetailToggle id="draftDisableCheck" draftDetailToToggle="disabled" draftDetailLabel="Draft Disabled" />

                <DraftDetailToggle id="draftForceOpenCheck" draftDetailToToggle="forceOpen" draftDetailLabel="Force Draft Open" />

                <button
                  type="button"
                  className="btn btn-sm btn-success px-3 shadow"
                  onClick={() => {
                    this.getDraftData();
                    this.getAllUsersData();
                  }}
                ><FontAwesomeIcon icon={iconRefresh} />
                </button>
              </div>
              {
                users.length > 0 && !this.state.loadingUsers && !this.state.loadingDraft
                  ? (
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Team Name</th>
                          <th scope="col">Song</th>
                          <th scope="col">Logo</th>
                          <th scope="col">DIP</th>
                          <th scope="col">Last Login</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          users.map((user, index) => this.state.draft[user] && (
                            <tr key={index}>
                              <td>
                                <div className="text-trim table-id" data-toggle="tooltip" data-placement="top" title={user}>
                                  {user}
                                </div>
                              </td>
                              <td>{this.state.users[user].displayName}</td>
                              <td>{this.state.users[user].email}</td>
                              <td>{this.state.draft[user].teamName}</td>
                              <td>
                                {
                                  (this.state.draft[user].songList && this.state.draft[user].songList.length > 0)
                                  && (
                                    <ol>
                                      {
                                        this.state.draft[user].songList.map((song, songIndex) => (
                                          <li key={songIndex} className="mb-1">
                                            {song.songTitle}
                                            {song.songUrl && (
                                              <span
                                                className="ml-1 text-italic"
                                                style={{ fontSize: '0.7rem' }}
                                              >
                                                (<a href={song.songUrl} target="_song">Song Link</a>)
                                              </span>
                                            )}
                                          </li>
                                        ))
                                      }
                                    </ol>
                                  )
                                }
                              </td>
                              <td>
                                {(this.state.draft[user].logoUrl && this.state.draft[user].logoUrl !== '') ? <a href={this.state.draft[user].logoUrl} target="_image"><img src={this.state.draft[user].logoUrl} alt="Logo" style={{ height: 75 }} /></a> : ''}
                                {this.state.draft[user].logoUpload && (
                                  <p style={{ fontSize: '0.7em' }}>{this.state.draft[user].logoUploadFilename}</p>
                                )}
                              </td>
                              <td>
                                <div className="custom-control custom-checkbox checkbox-xl mb-3">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="draftInPerson"
                                    checked={this.state.draft[user].draftInPerson}
                                    disabled
                                  />
                                  <label className="custom-control-label text-bold text-yellow-light" htmlFor="draftInPerson" />
                                </div>
                              </td>
                              <td>
                                <div className="text-trim table-lastLogin" data-toggle="tooltip" data-placement="top" title={this.state.users[user].lastLogin}>
                                  {this.state.users[user].lastLogin}
                                </div>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  )
                  : users.length === 0 ? 'None' : <div className="text-center"><FontAwesomeIcon icon={faSpinner} size="3x" spin className="text-yellow" /></div>
              }

            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default DraftAdmin;
