import React from 'react';

import version from '../../version.json';
import './footer.scss';

const Footer = () => (
  <div id="psf-footer-container">
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
    }}
    >
      <p className="ml-1">
        &copy; {new Date().getFullYear()} Packers Super Fans
      </p>
      <p className="mr-1">
        <span style={{ fontSize: '.75em' }}>v</span>{version.version}
      </p>
    </div>
  </div>
);

export default Footer;
