import { auth, firestore } from '../helpers/firebase';

export const createUserWithEmail = (email, password) => auth.createUserWithEmailAndPassword(email, password);

export const userLogout = () => auth.signOut().then(() => { window.location.href = '/'; });

export const updateUserProfile = (newProfile) => {
  const user = auth.currentUser;
  return user.updateProfile(newProfile);
};

export const userRole = async (userId) => {
  const returnUserRole = await firestore.collection('roles').doc(userId).get()
    .then(snapshot => ({ role: snapshot.data().role }))
    .catch(() => ({ role: 'guest' }));
  return returnUserRole;
};

export const forgotPassword = async email => auth.sendPasswordResetEmail(email)
  .then(() => ({ status: 'success', message: 'Email sent successfully' }))
  .catch(error => ({ status: 'error', message: error }));

export const updatePassword = async newPassword => auth.currentUser.updatePassword(newPassword)
  .then(() => ({ status: 'success', message: 'Password updated successfully' }))
  .catch(error => ({ status: 'error', message: error.message }));
