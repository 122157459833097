import React, { useState, useEffect } from 'react';
import { setDraftDetails as updateDraftDetails } from '../../../../../data/setDraftDetails';
import getDraftDetails from '../../../../../data/getDraftDetails';
import PropTypes from 'prop-types';

function DraftDetailToggle(props) {
  const [draftDetail, setDraftDetail] = useState(false);

  useEffect(() => {
    getDraftDetails().then(draft => {
      if (draft.success) {
        setDraftDetail(draft[props.draftDetailToToggle]);
      }
    });
  });

  const updateDraft = () => {
    updateDraftDetails({ [props.draftDetailToToggle]: !draftDetail }).then(result => {
      if (result.success) {
        setDraftDetail(!draftDetail);
      }
    });
  };

  return (
    <div id="psf-draft-admin-disable-draft">

      <div className="custom-control custom-checkbox checkbox-xl">
        <input type="checkbox" className="custom-control-input" id={props.id} checked={draftDetail} onChange={updateDraft} />
        <label className="custom-control-label" htmlFor={props.id}>
          {props.draftDetailLabel}
        </label>
      </div>

    </div>
  );
}

DraftDetailToggle.propTypes = {
  id: PropTypes.string.isRequired,
  draftDetailToToggle: PropTypes.string.isRequired,
  draftDetailLabel: PropTypes.string.isRequired,
};

export default DraftDetailToggle;