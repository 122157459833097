import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMoneyBillWave as moneyIcon,
  faFootballBall as footballIcon,
  faDollarSign as cashappIcon,
  faVideo as videoIcon
} from '@fortawesome/free-solid-svg-icons';
import { faSlack as slackIcon, faPaypal as paypalIcon } from '@fortawesome/free-brands-svg-icons';

import Countdown from '../../components/Countdown';
import Map from '../../components/Map';
import { getDraftInPersonCount, getDraftEntries } from '../../data/getDraftForAll';
import './dashboard.scss';

function Dashboard(props) {
  const icons = {
    cashApp: cashappIcon,
    football: footballIcon,
    money: moneyIcon,
    paypal: paypalIcon,
    slack: slackIcon,
    video: videoIcon,
  };

  const [draftInPersonCount, setDraftInPersonCount] = useState(0);
  const [draftEntries, setDraftEntries] = useState(0);

  useEffect(() => {
    getDraftInPersonCount().then(count => !count.error && setDraftInPersonCount(count.draftInPersonCount));
    getDraftEntries().then(count => !count.error && setDraftEntries(count.draftEntries));
  }, []);

  return (
    <div id="psf-draft-dashboard-container" className="psf-container container">

      <div className="container-fluid">
        <div className="row">

          <div className="col-sm-12 col-md-6 mb-2">
            <div className="shadow row-background m-2 p-2 full">

              <h2 className="text-shadow-dark">Key Dates</h2>
              <div className="pl-3">

                <div className="py-2">
                  <h5 className="text-yellow-light">Draft Info Due <small className="text-italic"> ({moment(props.draftInfo.draftInfoDue, 'LLLL').format('llll')})</small></h5>
                  {props.draftInfo.draftInfoDue ? <Countdown date={new Date(props.draftInfo.draftInfoDue)} /> : <span className="text-warning text-italic">Not Available</span>}
                </div>
                <div className="py-2">
                  <h5 className="text-yellow-light">Live Draft <small className="text-italic"> ({moment(props.draftInfo.draftDate, 'LLLL').format('llll')})</small></h5>
                  {props.draftInfo.draftDate ? <Countdown date={new Date(props.draftInfo.draftDate)} /> : <span className="text-warning text-italic">Not Available</span>}
                </div>
              </div>

            </div>
          </div>

          <div className="col-sm-12 col-md-6 mb-2">
            <div className="shadow row-background m-2 p-2 full">

              <h2 className="text-shadow-dark">{new Date().getFullYear().toString()} Draft Order</h2>
              <ol>
                {props.draftInfo.draftOrder.map((draftName, index) => <li key={`${index}_${draftName}`}>{draftName}</li>)}
              </ol>
            </div>

          </div>
        </div>{/* -- END ROW 1 -- */}

        <div className="row mt-3">

          <div className="col-sm-12 col-md-6 mb-2">
            <div className="shadow row-background m-2 p-2 full">

              <h2 className="text-shadow-dark">By The Numbers</h2>

              <div className="row pl-1 pr-3">
                <div className="col-6">
                  <div className="shadow row-background-yellow d-flex flex-column align-items-center mx-2 p-3 full">
                    <span className="number-text text-green"><h1>{draftEntries}</h1></span>
                    {/* <span className="number-text text-green"><h1>{`${parseFloat((draftEntries / props.draftInfo.draftOrder.length) * 100).toFixed(0)}%`}</h1></span> */}
                    <span className="box-label text-center">DRAFT INFO SUBMITTED</span>
                  </div>
                </div>

                <div className="col-6">
                  <div className="shadow row-background-yellow d-flex flex-column align-items-center mx-2 p-3 full">
                    <span className="number-text text-green"><h1>{draftInPersonCount}</h1></span>
                    <span className="box-label text-center">ATTENDING IN PERSON</span>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="col-sm-12 col-md-6 mb-2">
            <div className="shadow row-background m-2 p-2 full">

              <h2 className="text-shadow-dark">Housekeeping</h2>

              <div className="px-3">
                <h5 className="text-yellow-light">Key Draft Links <small className="text-italic">(subject to change)</small></h5>
                <ul className="list-unstyled px-3">
                  {
                    props.draftInfo.draftLinks.map((link, index) => {
                      return (
                        <li key={`draft-link-${index}`}>
                          <FontAwesomeIcon icon={icons[link.icon || 'football']} />
                          <span className="ml-2">
                            {
                              link.link
                                ? (
                                  <a href={link.link} target={`draft-link-${index}`}>
                                    {link.description}
                                  </a>
                                )
                                : (
                                  <span>
                                    {link.description}
                                    <small className="text-italic ml-1">(link coming soon)</small>
                                  </span>
                                )
                            }
                          </span>
                        </li>
                      );
                    })
                  }
                </ul>

                <h5 className="text-yellow-light">League Payment Options <small className="text-italic text-warn">(Due by Draft Day)</small></h5>
                <ul className="list-unstyled px-3">
                  <li><FontAwesomeIcon icon={icons.paypal} /><span className="ml-2"><a href="https://paypal.com" target="_payment">PayPal</a></span>: frievalt@gmail.com</li>
                  <li><FontAwesomeIcon icon={icons.cashApp} /><span className="ml-2"><a href="https://cash.app" target="_payment">Cash App</a></span>: $nickaf</li>
                  <li><FontAwesomeIcon icon={icons.money} /><span className="ml-2 text-italic">Cash Day Of Draft</span></li>
                </ul>
              </div>

            </div>
          </div>
        </div>{/* -- END ROW 2 -- */}

        <div className="row mt-3">

          <div className="col-12 mb-2">
            <div className="shadow row-background m-2 py-2 px-3 full">

              <h2 className="text-shadow-dark">Draft Location</h2>
              {(props.draftInfo.draftLocation && props.draftInfo.draftLocation.latitude && props.draftInfo.draftLocation.longitude) ? <Map draftLocation={props.draftInfo.draftLocation} /> : <p className="text-warning text-italic">Map Unavailable</p>}

              <article className="mt-2"><span className="text-bold mr-1">Address:</span> {(props.draftInfo.draftLocation && props.draftInfo.draftLocation.address) ? <a href={`geo:${props.draftInfo.draftLocation.latitude},${props.draftInfo.draftLocation.longitude}`}>{props.draftInfo.draftLocation.address}</a> : <span className="text-warning text-italic">Not Available</span>}</article>

            </div>
          </div>

        </div>{/* -- END ROW LAST -- */}
      </div>

    </div >
  );
}

Dashboard.defaultProps = {
  draftInfo: {
    draftLocation: {
      latitude: 0,
      longitude: 0,
      address: ''
    }
  }
};

export default Dashboard;