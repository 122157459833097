import React from 'react';
import Countdown from '../../components/Countdown';
import Title from '../../components/Title';
import psfLogo from '../../images/logo/packers_logo.svg';


import './home.scss';

const Home = props => (
  <div id="psf-ffl-home-container" className="psf-container container full">

    <div className="d-flex flex-column justify-content-between align-items-center full">

      {props.draftDate && (
        <div>
          <Title text="Countdown to the Draft" />
          <div className="d-block">
            <Countdown date={new Date(props.draftDate)} />
          </div>
        </div>
      )}

      <div className="d-flex flex-column justify-content-center align-items-center mb-6">
        <div className="logo-main-1 mb-4">
          <div className="logo-main-2">
            <img className="pulse" src={psfLogo} alt="PSF Logo" />
          </div>
        </div>
      </div>

      <div className="alert alert-warning alert-dismissible fade show" role="alert">
        If team name, song, and image are not updated... it will be chosen for you, you have been Warned <span role="img" aria-label="smile emoji">&#128521;</span>
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
);

export default Home;
