import { firestore } from '../helpers/firebase';

const getUsers = async () => {
  const returnUsers = await firestore.collection('users').get()
    .then((snapshot) => {
      let users = {};
      snapshot.forEach((doc) => { users = { ...users, [doc.id]: doc.data() }; });
      return { error: false, users };
    })
    .catch(error => ({ error: true, message: error.message, users: [] }));
  return returnUsers;
};

export default getUsers;
