import { firestore } from '../helpers/firebase';

const getDraftDetails = async () => {
  const year = new Date().getFullYear().toString();
  const minimumDraftInfo = {
    draftInfoDue: null,
    draftDate: null,
    draftOrder: [],
    draftLinks: [],
    disabled: true,
    forceOpen: false,
  };
  const returnDraftDetails = await firestore.collection('draft').doc(year).get()
    .then(snapshot => (snapshot.exists ? ({ success: true, ...minimumDraftInfo, ...snapshot.data() }) : ({ success: true, ...minimumDraftInfo })))
    .catch(error => ({ success: false, ...minimumDraftInfo, error }));
  return returnDraftDetails;
};

export default getDraftDetails;
