import React, { useEffect } from 'react';
import Speak from '../../helpers/speakHelper';

function Welcome() {
  const speak = new Speak({
    text: 'Welcome to Heart Failure FFL, the best Fantasy Football League on the planet!',
    rate: 20.0,
  });
  useEffect(() => {
  }, []);
  return (
    <div id="psf-draft-welcome-container" className="psf-container container full">
      <div className="d-flex flex-column justify-content-center align-items-center full">
        <button type="button" className="btn btn-lg btn-primary mb-5 shadow" onClick={() => speak.speak()}>Play Welcome Message</button>
      </div>
    </div>
  );
};

export default Welcome;