import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { auth } from '../../../helpers/firebase';
import setUserData from '../../../data/setUserData';
import { toastSuccess, toastError } from '../../../helpers/toastHelpers';
import { forgotPassword } from '../../../helpers/authHelpers';
import './login.scss';

const initialState = {
  username: '',
  password: '',
  error: '',
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.handleInput = this.handleInput.bind(this);
    this.submitLogin = this.submitLogin.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  handleInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  submitLogin(e) {
    e.preventDefault();
    auth.signInWithEmailAndPassword(this.state.username, this.state.password)
      .then(({ user }) => {
        this.setState(initialState);
        this.props.updateUser(user);
        setUserData(user.uid, { email: user.email, displayName: user.displayName, lastLogin: Date().toLocaleString() }).then(response => console.log(response.message));
        $('#loginModal').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
      })
      .catch(error => this.setState({ error: error.message }));
  }

  resetPassword(e) {
    e.preventDefault();
    forgotPassword(this.state.username)
      .then(result => (result.status === 'success' ? toastSuccess('Reset Email Sent Successfully') : toastError('Reset Email Unable to be Sent')));
  }

  render() {
    return (
      <div id="psf-ffl-login">
        <div className="modal fade" id="loginModal" tabIndex="-1" role="dialog" aria-labelledby="loginModalLabel" aria-hidden="true" data-backdrop>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <form className="px-4 py-3">
                <div className="modal-header">
                  <h5 className="modal-title" id="loginModalLabel">Login</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">

                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input
                      type="text"
                      className="form-control"
                      name="username"
                      aria-describedby="emailHelp"
                      placeholder="Enter Email"
                      onChange={this.handleInput}
                      value={this.state.username}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="Password"
                      onChange={this.handleInput}
                      value={this.state.password}
                    />
                  </div>
                  <span className="text-danger">{this.state.error}</span>
                </div>
                <div className="modal-footer">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button> */}
                    <button
                      type="button"
                      onClick={this.resetPassword}
                      className="btn btn-link"
                      disabled={this.state.username === ''}
                      data-dismiss="modal"
                    >Forgot Password?
                    </button>
                    <button type="submit" onClick={this.submitLogin} className="btn btn-primary">Login</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  updateUser: PropTypes.func.isRequired,
};

export default Login;
