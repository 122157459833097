import React, { Component } from 'react';
import Title from '../../components/Title';
import { createUserWithEmail, updateUserProfile } from '../../helpers/authHelpers';
import { titleHelper } from '../../helpers/titleHelper';
import setUserData from '../../data/setUserData';
import { toastSuccess, toastError } from '../../helpers/toastHelpers';
import './signUp.scss';

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      name: '',
      photoUrl: '',
      error: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    titleHelper('Sign Up');
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();

    if (this.state.email && this.state.password && this.state.name) {
      createUserWithEmail(this.state.email, this.state.password)
        .then((newUser) => {
          this.props.updateUser(newUser.user);
          updateUserProfile({ displayName: this.state.name, photoUrl: this.state.photoUrl })
            .then(() => {
              // write user data to the 'users' collection
              setUserData(newUser.user.uid, { email: this.state.email, displayName: this.state.name, lastLogin: Date().toLocaleString() }).then(response => console.log(response.message));
              toastSuccess('User Successfully Created');
              window.location.href = '/';
            })
            .catch((error) => {
              toastSuccess('User Successfully Created');
              toastError(error.message);
              window.location.href = '/';
            });
        })
        .catch(error => this.setState({ error: null }, () => toastError(error.message)));
    } else {
      this.setState({ error: 'Email, Password, and Name are all mandatory' });
    }
  }

  render() {
    return (
      <div id="psf-signup-container" className="psf-container container">
        <Title text="Sign Up" />

        <div className="container-fluid">
          <div className="row shadow row-background p-5">
            <div className="col-sm-12 col-md-6">
              <form>
                <fieldset>

                  <div className="form-group">
                    <label htmlFor="email">Email address</label>
                    <input type="email" className="form-control" id="email" name="email" aria-describedby="emailHelp" placeholder="Your email" onInput={this.handleChange} value={this.state.email} />
                    <small id="emailHelp" className="form-text text-mute">It will be your username to login</small>
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" className="form-control" id="password" name="password" aria-describedby="passwordHelp" placeholder="Your password" onInput={this.handleChange} value={this.state.password} />
                  </div>

                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" className="form-control" id="name" name="name" aria-describedby="nameHelp" placeholder="Your Name" onInput={this.handleChange} value={this.state.name} />
                    <small id="nameHelp" className="form-text text-mute">Please use your real name to make life easier</small>
                  </div>

                  <div className="form-group">
                    <label htmlFor="name">Photo/Avatar Link</label>
                    <input type="text" className="form-control" id="photoUrl" name="photoUrl" aria-describedby="photoUrlHelp" placeholder="URL" onInput={this.handleChange} value={this.state.photoUrl} />
                  </div>

                  <button type="submit" className="btn btn-lg btn-success mt-3" onClick={this.handleSubmit}>Sign Up</button>
                  {this.state.error ? <small id="errorMessage" className="d-block alert alert-danger mt-3">{this.state.error}</small> : ''}
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default SignUp;
