import React, { useState } from 'react';
import PropTypes from 'prop-types';

function TeamLocation(props) {
  const [draftInPerson, setDraftInPerson] = useState(props.initialDraftInPerson);

  const handleChange = () => {
    const newValue = !draftInPerson;

    props.onChange(newValue);
    setDraftInPerson(newValue);
  };

  return (
    <div className={`row ${props.className}`}>
      <h3 className="d-block mb-4">Location</h3>

      <div className="col-12">
        <div className="form-group mb-4">
          <div className="custom-control custom-checkbox checkbox-xl mb-3">
            <input
              type="checkbox"
              className="custom-control-input"
              id="draftInPerson"
              checked={draftInPerson}
              onChange={handleChange}
            />
            <label className="custom-control-label text-bold text-yellow-light" htmlFor="draftInPerson">
              <span className="ml-1">Draft In-Person</span>
            </label>
          </div>
          <small className="form-text text-mute text-italic">Will you be planning on attending in-person this year? This will help with food and logistical planning.</small>
        </div>
      </div>

    </div>
  );
}

TeamLocation.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  initialDraftInPerson: PropTypes.bool,
};
TeamLocation.defaultProps = {
  onChange: () => null,
  className: '',
  initialDraftInPerson: false,
};

export default TeamLocation;