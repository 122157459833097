import React, { useState, useEffect } from 'react';
import {
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import moment from 'moment';

import { auth } from './helpers/firebase';
import { userRole } from './helpers/authHelpers';
import setUserData from './data/setUserData';
import getDraftDetails from './data/getDraftDetails';

import Home from './views/Home';
import Dashboard from './views/Dashboard';
import SignUp from './views/SignUp';
import LegendsClub from './views/LegendsClub';
import Draft from './views/Draft';
import DraftAdmin from './views/Draft/DraftAdmin';
import DraftOrder from './views/DraftOrder';
import Welcome from './views/Welcome';
import Nav from './components/Nav';
import Footer from './components/Footer';
import PrivateRoute from './components/PrivateRoute';
import PasswordUpdateModal from './components/Modals/PasswordUpdate';
import LoginModal from './components/Modals/Login';

function App() {
  const [user, setUser] = useState(null);
  const [draftInfo, setDraftInfo] = useState({
    draftInfoDue: null,
    draftDate: null,
    disabled: true
  });

  useEffect(() => {
    const getDraftInfo = () => {
      getDraftDetails().then(data => {
        setDraftInfo({ ...data });
      });
    };

    auth.onAuthStateChanged((newUser) => {
      if (newUser) {
        updateUser(newUser);
        setUserData(newUser.uid, { email: newUser.email, displayName: newUser.displayName, lastLogin: Date().toLocaleString() }).then(response => console.log(`Loading user... ${response.message}`));
      }
    });
    getDraftInfo();
  }, []);

  const updateUser = (userObj) => {
    userRole(userObj.uid).then(data => setUser({ ...userObj, ...data }));
  };

  const authenticated = auth.currentUser !== null;
  const disableDraft = draftInfo.disabled;
  const expiredDraft = !draftInfo.forceOpen && moment(new Date()).isAfter(moment(draftInfo.draftInfoDue, 'LLLL'));

  return (
    <React.Fragment>

      <Nav authenticated={authenticated} user={user} />
      <section id="main-container">
        <Routes>
          <Route path='/signup' element={<SignUp updateUser={updateUser} />} />
          <Route path="/legends-club" element={<LegendsClub />} />
          <Route path="/draft-order" element={<DraftOrder />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route
            path="/draft"
            element={<PrivateRoute component={() => <Draft user={user} disableDraft={disableDraft} expiredDraft={expiredDraft} />} authenticated={authenticated} />}
          />
          <Route
            path="/draft/admin"
            element={<PrivateRoute component={() => <DraftAdmin user={user} />} authenticated={authenticated} />}
          />
          <Route
            path="/dashboard"
            element={<PrivateRoute component={() => <Dashboard draftInfo={{ ...draftInfo, disableDraft, expiredDraft }} />} authenticated={authenticated} />}
          />
          <Route path='/*' element={authenticated ? <Navigate to="/dashboard" /> : <Home showDraftCountdown={!expiredDraft} draftInfoDue={draftInfo.draftInfoDue || null} draftDate={draftInfo.draftDate || null} />} />
        </Routes>

        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable={false}
          pauseOnHover
          transition={Slide}
          theme="colored"
        />

        <PasswordUpdateModal />
        <LoginModal updateUser={updateUser} />
      </section>
      <Footer />

    </React.Fragment>
  );
}

export default App;
