import { firestore } from '../helpers/firebase';

const setUser = async (userId, data) => {
  const setUserData = await firestore.collection('users').doc(userId).set(data)
    .then(() => ({ success: true, message: 'Successfully Updated!' }))
    .catch(error => ({ success: false, message: `Error Saving: ${error}` }));
  return setUserData;
};

export default setUser;
