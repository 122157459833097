import { firestore } from '../helpers/firebase';

const getDraft = async (userId) => {
  const year = new Date().getFullYear().toString();
  let returnDraft = null;
  try {
    returnDraft = await firestore
      .collection('draft')
      .doc(year)
      .collection('draft')
      .doc(userId)
      .get()
      .then(snapshot => (snapshot.exists ? ({ ...snapshot.data(), found: true, error: false }) : ({ found: false, error: false })))
      .catch(error => ({ error: false, found: false }));
  } catch (error) {
    returnDraft = { error: error.message, found: false };
  }
  return returnDraft;
};

export default getDraft;
