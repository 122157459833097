import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'jquery';
import 'popper.js';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faStroopwafel } from '@fortawesome/free-solid-svg-icons';
import * as serviceWorker from './serviceWorker';
import App from './App';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './styles/index.scss';

library.add(faStroopwafel);

const Root = () => {
  // ### MOBILE SAFARI & CHROME VIEWPORT ISSUE FIX ###

  const mobileFix = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  mobileFix();
  window.addEventListener('resize', mobileFix);

  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};

const container = document.getElementById('psf-heartfailure-app');

const root = ReactDOMClient.createRoot(container);

root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
