import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignOutAlt as logoutIcon,
  faStar as starIcon,
  faToolbox as adminIcon,
  faKey as passwordIcon,
} from '@fortawesome/free-solid-svg-icons';

import { userLogout } from '../../helpers/authHelpers';

import psfLogo from '../../images/logo/psf_logo_sm2.png';
import psfLogoSmall from '../../images/logo/psf_logo_sm1.png';
import './nav.scss';

const Nav = (props) => {
  const path = useLocation().pathname;
  const navigate = useNavigate();
  return (
    <nav className="navbar navbar-expand-md navbar-light psf-navbar">
      <a className="navbar-brand align-self-stretch" href="/">
        <img src={psfLogoSmall} className="d-inline-block align-top" alt="" />
      </a>

      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#psfNavDropDown" aria-controls="psfNavDropDown" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" />
      </button>

      <div className="collapse navbar-collapse justify-content-between" id="psfNavDropDown">

        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className={`nav-link ${path === '/' ? 'active' : ''}`}
              href="/"
            >Home
            </a>
          </li>

          <li className="nav-item">
            <a
              className={`nav-link ${path === '/legends-club' ? 'active' : ''}`}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                $('.navbar-collapse').collapse('hide');
                navigate('/legends-club');
              }}
            >Legends Club
            </a>
          </li>

          {props.authenticated
            && (
              <li className="nav-item">
                <a
                  className={`nav-link ${path === '/draft' ? 'active' : ''}`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    $('.navbar-collapse').collapse('hide');
                    navigate('/draft');
                  }}
                >
                  <FontAwesomeIcon icon={starIcon} className="mr-2 text-danger" />
                  My Draft
                  <FontAwesomeIcon icon={starIcon} className="ml-2 text-danger" />
                </a>
              </li>
            )}
          {
            (props.user && props.user.role === 'admin')
            && (

              <li className="nav-item">
                <a
                  className={`nav-link ${path === '/draft/admin' ? 'active' : ''}`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    $('.navbar-collapse').collapse('hide');
                    navigate('/draft/admin');
                  }}
                >
                  Admin
                  <FontAwesomeIcon icon={adminIcon} className="ml-2 text-green" />
                </a>
              </li>


            )}
        </ul>

        {props.user
          ? (
            <div id="psf-ffl-user" className="dropdown">
              <button
                id="userButton"
                type="button"
                className="btn btn-psf-green btn-sm my-2 my-sm-0 align-self-end"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img id="loginIcon" src={psfLogo} alt="Login" />
                {props.user.email}
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <a
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#updatePasswordModal"
                  href="#"
                >
                  Update Password
                  <FontAwesomeIcon icon={passwordIcon} className="ml-2 text-green" />
                </a>
                <div className="dropdown-divider" />
                <a className="dropdown-item" onClick={() => userLogout()}><FontAwesomeIcon icon={logoutIcon} /> Logout</a>
              </div>
            </div>
          )
          : (
            <div id="psf-ffl-login">
              <button
                id="loginButton"
                type="button"
                className="btn btn-psf-green btn-sm my-2 my-sm-0 align-self-end"
                data-toggle="modal"
                data-target="#loginModal"
              >
                Login <img id="loginIcon" src={psfLogo} alt="Login" />
              </button>
            </div>
          )}
      </div>
    </nav>
  );
};

// Nav.propTypes = {
//   updateUser: PropTypes.func.isRequired,
// };

export default Nav;
