import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLevelUpAlt as upIcon
} from '@fortawesome/free-solid-svg-icons';

const initialNewSong = {
  songTitle: '',
  songUrl: '',
};
function TeamSong(props) {
  const [songList, setSongList] = useState(props.initialSongList);
  const [newSong, setNewSong] = useState(initialNewSong);

  const addSong = () => {
    const newSongList = [...songList, {
      ...newSong,
    }];
    props.onChange(newSongList);
    setSongList(newSongList);
    setNewSong(initialNewSong);
  };

  const deleteSong = (index) => {
    const newSongList = JSON.parse(JSON.stringify(songList));
    newSongList.splice(index, 1);
    props.onChange(newSongList);
    setSongList(newSongList);
  };

  const disableSong = songList.length >= 3;
  return (
    <div className={`row flex-column ${props.className}`}>
      <h3 className="d-block mb-4">Team Song</h3>

      <div className="row px-3">

        <div className="col-12 col-md-6">
          <label className="label text-yellow-light" htmlFor="songList">
            Song List
            <span
              style={{
                fontSize: '0.5em',
                fontStyle: 'italic',
                marginLeft: 5,
              }}
            >
              (max 3 songs)
            </span>
          </label>
          {
            songList.length > 0
              ? (
                <ul id="songList" className="list-group">
                  {
                    songList.map((song, index) => (
                      <li key={index} className="list-group-item">
                        <div className="list-group-item-container">

                          <div>
                            <span style={{ fontSize: '1.1em' }}>
                              {`${index + 1}. ${song.songTitle}`}
                            </span>
                            <small className="form-text text-dark">
                              <span className="text-bold mr-1">Link:</span>
                              <span className="text-italic">{song.songUrl ? <a href={song.songUrl} target="_new">{song.songUrl}</a> : 'none'}</span>
                            </small>
                          </div>
                          <button
                            type="button"
                            className="btn"
                            onClick={() => deleteSong(index)}
                          >
                            &#10005;
                          </button>

                        </div>
                      </li>
                    ))
                  }
                </ul>
              )
              : (
                <p className="ml-3" style={{ fontStyle: 'italic' }}>No songs added yet</p>
              )
          }
        </div>

        <div className="col-12 col-md-6">

          <div className="row">
            <div className="col-12">
              <div className="form-group mb-4">
                <label className="label text-yellow-light" htmlFor="songTitle">Song Title</label>
                <input
                  type="text"
                  id="songTitle"
                  className="form-control"
                  placeholder="Song Title"
                  value={newSong.songTitle}
                  onChange={e => setNewSong({ ...newSong, songTitle: e.target.value })}
                  disabled={disableSong}
                  required
                />
              </div>
            </div>

          </div>

          <div className="row">

            <div className="col-12">
              <div className="form-group mb-4">
                <label className="label text-yellow-light" htmlFor="songUrl">Song Link</label>
                <input
                  type="text"
                  id="songUrl"
                  className="form-control"
                  placeholder="Song Link"
                  value={newSong.songUrl}
                  onChange={e => setNewSong({ ...newSong, songUrl: e.target.value })}
                  disabled={disableSong}
                />
                <small className="form-text text-mute text-italic">Link to song (i.e. YouTube)</small>
                <button
                  type="button"
                  className="btn btn-warning btn-sm mt-2"
                  onClick={addSong}
                  disabled={disableSong || newSong.songTitle === ''}
                >Add
                </button>
                {JSON.stringify(initialNewSong) !== JSON.stringify(newSong) && (
                  <small className="form-text text-warning text-italic text-shadow-light mt-2 ml-3">
                    <FontAwesomeIcon icon={upIcon} size="lg" flip="horizontal" className="mr-1 mb-2" />
                    Song information will not be saved until you add it to your song list
                  </small>
                )}
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  );
}

TeamSong.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  initialSongList: PropTypes.array,
};
TeamSong.defaultProps = {
  onChange: () => null,
  className: '',
  initialSongList: [],
};

export default TeamSong;