import React, { Component } from 'react';
import FileUploader from 'react-firebase-file-uploader';
import Switch from 'react-switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faLock as lockIcon,
  faStopwatch as expiredIcon
} from '@fortawesome/free-solid-svg-icons';

import Title from '../../components/Title';
import { auth, storage } from '../../helpers/firebase';
import { toastSuccess, toastError } from '../../helpers/toastHelpers';
import getDraftForUser from '../../data/getDraftForUser';
import setDraftForUser from '../../data/setDraftForUser';
import getImageUrlFromFilename from '../../data/getImageUrl';
import TeamName from './components/TeamName';
import TeamSong from './components/TeamSong';
import TeamLocation from './components/TeamLocation';
import './draft.scss';

const initialDraftState = {
  draftInPerson: false,
  error: false,
  found: false,
  logoUpload: false,
  logoUploadFilename: '',
  logoUrl: '',
  songList: [],
  teamName: '',
};

class Draft extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: this.props.user,
      draft: initialDraftState,
      previewImageUrl: '',
      loading: true,
      changed: false,
    };

    this.deleteImage = this.deleteImage.bind(this);
    this.getDraftData = this.getDraftData.bind(this);
    this.getImageUrl = this.getImageUrl.bind(this);
    this.saveDraftData = this.saveDraftData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  updateDraft = newDraft => this.setState({ draft: { ...this.state.draft, ...newDraft }, changed: true });

  componentDidMount() {
    auth.currentUser ? this.getDraftData() : this.props.history.push('/');
  }

  getDraftData() {
    getDraftForUser(this.state.user.uid)
      .then((draftData) => {
        const draft = { ...initialDraftState, ...draftData };
        draft.songList = (draft.songList && !Array.isArray(draft.songList)) ? JSON.parse(draft.songList) : [];
        this.setState({ draft, loading: false }, this.getImageUrl);
      });
  }

  getImageUrl() {
    getImageUrlFromFilename(this.state.draft.logoUploadFilename).then(url => (url ? this.setState({ previewImageUrl: url }) : ''));
  }

  deleteImage() {
    const year = new Date().getFullYear().toString();
    storage.ref(`images/${year}/${this.state.draft.logoUploadFilename}`).delete().then(() => console.log('Successfully Deleted'));
  }

  saveDraftData() {
    const data = setDraftForUser(this.state.user.uid, {
      draftInPerson: this.state.draft.draftInPerson,
      logoUpload: !!(this.state.draft.logoUpload && this.state.draft.logoUploadFilename),
      logoUploadFilename: this.state.draft.logoUpload ? this.state.draft.logoUploadFilename : '',
      logoUrl: this.state.draft.logoUrl,
      songList: JSON.stringify(this.state.draft.songList),
      teamName: this.state.draft.teamName,
    });
    data.then((result) => {
      if (result.success) {
        !this.state.draft.logoUpload && this.deleteImage();
        this.getDraftData();
        toastSuccess('Successfully Saved');
      } else {
        toastError('There was an issue saving!');
        console.log('Saving Error Details\n', result.message);
      }
    });
  }

  handleChange(e) {
    const newDraft = this.state.draft;
    newDraft[e.target.id] = e.target.value;
    this.setState({ draft: newDraft });
  }

  handleChecked(checked) {
    const newDraft = this.state.draft;
    newDraft.logoUpload = checked;
    this.setState({ draft: newDraft });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.saveDraftData();
  }

  render() {
    this.state.draft.error && toastError('Error retrieving data');
    const year = new Date().getFullYear().toString();
    return (
      <div id="psf-draft-container" className="psf-container container">
        {
          (this.props.expiredDraft || this.props.disableDraft)
          && (
            <div className="draft-closed p-2">
              {(this.props.disableDraft && !this.props.expiredDraft) && (
                <div className="alert alert-danger d-flex flex-column justify-content-center align-items-center" role="alert">
                  <FontAwesomeIcon icon={lockIcon} size="10x" className="p-4" />
                  <h3 className="p-4">Draft is currently disabled, check back later.</h3>
                </div>
              )}
              {this.props.expiredDraft && (
                <div className="alert alert-warning d-flex flex-column justify-content-center align-items-center" role="alert">
                  <FontAwesomeIcon icon={expiredIcon} size="10x" className="p-4" />
                  <p className="p-4">
                    <b>Dang!</b> Looks like Draft Submission is closed... hopefully you submitted your Draft Info already!
                  </p>
                </div>
              )}
            </div>
          )
        }
        <Title text="Draft" />
        {!this.state.loading
          ? (
            <form>
              <fieldset disabled={this.state.draft.error || this.props.disableDraft || this.props.expiredDraft}>


                <div className="container-fluid">

                  <TeamName
                    initialName={this.state.draft.teamName}
                    className="shadow row-background p-4 mt-2 mb-2"
                    onChange={(value) => this.updateDraft({ teamName: value })}
                  />

                  <TeamSong
                    initialSongList={this.state.draft.songList}
                    className="shadow row-background p-4 mt-4 mb-2"
                    onChange={(value) => this.updateDraft({ songList: value })}
                  />

                  <div className="row shadow row-background p-4 mt-4 mb-2">
                    <h3 className="d-block mb-4">Team Logo</h3>

                    <div className="col-12">
                      <div className="form-group mb-4">
                        <label className="label text-yellow-light" htmlFor="logoUrl">Team Logo</label>

                        <p className="text-mute">
                          <span className="text-bold">You've Chosent to:</span>
                          <span className="ml-3">{this.state.draft.logoUpload ? 'Upload' : 'Provide a Link'}</span>
                        </p>
                        <div className="container-fluid">
                          <div className="row">

                            <div className="col-sm-12 col-md-auto mt-4">
                              <Switch
                                checked={this.state.draft.logoUpload}
                                onChange={this.handleChecked}
                                onColor="#668466"
                                onHandleColor="#06342D"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="material-switch"
                              />
                            </div>
                            {
                              !this.state.draft.logoUpload
                                ? (
                                  <div className="col mt-4">
                                    <input type="text" className="form-control" id="logoUrl" placeholder="Link to Logo" value={this.state.draft.logoUrl} onChange={this.handleChange} required />
                                  </div>
                                )
                                : (
                                  <div className="col-sm-12 col-md-auto mt-4">
                                    <FileUploader
                                      accept={['image/png', 'image/jpg', 'image/jpeg', 'image/gif']}
                                      name="avatar"
                                      filename={(file) => {
                                        const filename = `${this.state.user.uid}.${file.name.split('.')[1]}`;
                                        const { draft } = this.state;
                                        draft.logoUploadFilename = filename;
                                        this.setState({ draft });
                                        return filename;
                                      }}
                                      storageRef={storage.ref(`images/${year}`)}
                                    // randomizeFilename
                                    // onUploadStart={this.handleUploadStart}
                                    // onUploadError={this.handleUploadError}
                                    // onUploadSuccess={this.handleUploadSuccess}
                                    // onProgress={this.handleProgress}
                                    />
                                  </div>
                                )
                            }
                            {
                              (this.state.draft.logoUploadFilename && this.state.draft.logoUpload && this.state.previewImageUrl)
                              && (
                                <div className="col-12 col-md-auto">
                                  <span className="d-block text-mute sub-head-text mb-2">Logo Preview:</span>
                                  <img src={this.state.previewImageUrl} alt="Team Logo Preview" style={{ maxWidth: 300 }} />
                                </div>
                              )
                            }
                          </div>
                        </div>

                        <small className="form-text text-mute text-italic mt-2">
                          This will be the image used on draft day
                          <span className="text-bold text-warn ml-2">
                            Even if it is in ESPN, make sure it's here too!
                          </span>
                        </small>
                      </div>

                    </div>

                  </div>

                  <TeamLocation
                    initialDraftInPerson={this.state.draft.draftInPerson}
                    className="row-background p-4 mt-4 mb-2"
                    onChange={(value) => this.updateDraft({ draftInPerson: value })}
                  />

                  <div className="row mt-2 mb-5">

                    <div className="col-sm-12 col-md-4 col-lg-2">
                      <button type="submit" className="btn btn-lg btn-success mt-3 py-3 btn-block shadow" onClick={this.handleSubmit}>Save</button>
                    </div>

                  </div>
                </div >

              </fieldset >
            </form >

          )
          : <div className="text-center"><FontAwesomeIcon icon={faSpinner} size="3x" spin className="text-yellow" /></div>
        }
      </div>
    );
  }
}

export default Draft;
